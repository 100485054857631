import React, { ReactNode, useEffect, useRef } from "react";
import "./index.css";
import { useIntersectionObserver } from "usehooks-ts";
import cn from "classnames";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

interface ListTemplate<T extends { values: ReactNode[] }> {
  data: T[][];
  headers: { name: string }[];
  onListEnd: () => void;
  isLoading: boolean;
}

const ListTemplate = <T extends { values: ReactNode[] }>(props: ListTemplate<T>) => {
  const { data, onListEnd, isLoading, headers } = props;
  const containerRef = useRef(null)
  const firstColumnRef = useRef<any>(null)
  const tableScrollRef = useRef<any>(null)
  const lastScrollPosition = useRef(0)

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  })

  const isVisible = isIntersecting

  useEffect(() => {
    if (isVisible && onListEnd && !isLoading) {
      onListEnd();
    }
  }, [isVisible, ref]);

  const onBodyScroll = (event: any) => {
    const {scrollTop} = event.target
    if(scrollTop === lastScrollPosition.current) {
      event.preventDefault()
    }
    lastScrollPosition.current = scrollTop
    firstColumnRef.current.scrollTo(0, event.target.scrollTop)
  }

  return (
    <div {...{ className: `ListTemplate hidden-scrollbar`, ref: containerRef }} style={{overflow: 'hidden'}}>
      {!!data && (
        <div style={{display: 'flex', overflow: 'hidden', height: '100%'}}>
          <div ref={firstColumnRef} style={{overflow: 'hidden', height: '100%', minWidth: 200, marginRight: 1}} className={"hidden-scrollbar"}>
            <TableLayout headers={headers.slice(0, 1)} data={data.map(row => row.slice(0,1))}/>
            <div
              className={cn("ListTemplate_end-list", isLoading && "ListTemplate_end-list_loading")}>
              <SkeletonLocal isLoading={isLoading}/>
            </div>
          </div>
          <div onScroll={onBodyScroll} ref={tableScrollRef} style={{overflow: 'scroll', height: '100%'}} className={"hidden-scrollbar"}>
            <TableLayout headers={headers.slice(1, headers.length)} data={data.map(row => row.slice(1,headers.length))}/>
            <div
              ref={ref}
              className={cn("ListTemplate_end-list", isLoading && "ListTemplate_end-list_loading")}>
              <SkeletonLocal isLoading={isLoading}/>
            </div>
          </div>
        </div>
        )}
    </div>
  );
};

const SkeletonLocal = ({isLoading}: {isLoading: boolean}) => {
  if(!isLoading) return null
  return (
    <SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
      <div style={{flexDirection: 'column', gap: 10}}>
        <Skeleton width={200} height={24} borderRadius={9}/>
        <Skeleton width={200} height={24} borderRadius={9}/>
        <Skeleton width={200} height={24} borderRadius={9}/>
      </div>
    </SkeletonTheme>
  )
}

const TableLayout = ({headers, data}: any) => {
  return (
    <table {...{ className: `ListTemplateContainerTable` }}>
      <thead {...{ className: `border-radius-6` }}>
      <tr>
        {headers?.map((item: any, key: number) => (
          <th
            {...{
              key: key,
              className: `padding-10 text-dotted-end font-size-12`,
            }}>
            {item.name}
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {data.map((row: any, key: number) => {
        return (
          <tr {...{ className: `border-radius-6`, key: key }}>
            {row.map((item: any, index: number) => {
              return (
                <td
                  {...{
                    key: index + '_' +key,
                    className: `text-dotted-end font-size-12 ListTemplateContainerTableTd ${
                      statusBilling[key] && statusBilling[key].type && "name" in item && item.name === "Статус"
                        ? `ListTemplateContainerTable_${statusBilling[key].type}`
                        : ""
                    }`,
                    style: { ...{ verticalAlign: "middle" } },
                  }}>
                  {item.values ? item.values[0] : "Нет данных"}
                </td>
              );
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  )
}

export default ListTemplate;

export const statusBilling = [
  {
    type: "success",
  },
  {
    type: "warning",
  },
  {
    type: "error",
  },
];
