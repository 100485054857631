import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyARsWjLJ2cdtkW8WSOp20y-Rf0f-LWiing",
  authDomain: "trackup-d6475.firebaseapp.com",
  projectId: "trackup-d6475",
  storageBucket: "trackup-d6475.appspot.com",
  messagingSenderId: "314977584000",
  appId: "1:314977584000:web:5e34dc0a2e66077ad9b765",
  measurementId: "G-4J15CJSCCN",
};

let firebaseApp = null
let messaging = null

// Initialize Firebase
try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (e) {}

export { messaging }

export const getFcmToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: "BNUJup3RJWFfBMIyav1lohTSLbXp-xBHkuZtQjZYCJaBVqPZvxhn1VSWMNIVnMDAFfI4HQdT0cr9fIeMWvrTfj8" });
};
